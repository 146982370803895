import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import hotelA from "./components/hotel-a";
import hotelB from "./components/hotel-b";
import hotelC from "./components/hotel-c";
import hotelD from "./components/hotel-d";
import { mapMutations } from "vuex";
export default {
  metaInfo: {
    title: "防伪溯源系统",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "防伪溯源系统,常达"
    }, {
      name: "description",
      content: "防伪溯源系统"
    }]
  },
  components: {
    top: top,
    hotelA: hotelA,
    hotelB: hotelB,
    hotelC: hotelC,
    hotelD: hotelD
  },
  data: function data() {
    return {
      list: [{
        image: require("@/assets/hotel/swiper2/1.jpg"),
        text: "白酒",
        imgOffset: "-30%",
        icon: require("@/assets/hotel/swiper2/icon1.png"),
        icon1: require("@/assets/hotel/swiper2/icon1c.png"),
        to: "/cosmetic"
      }, {
        image: require("@/assets/hotel/swiper2/5.jpg"),
        text: "化妆品",
        imgOffset: "-50%",
        icon: require("@/assets/hotel/swiper2/icon5.png"),
        icon1: require("@/assets/hotel/swiper2/icon5c.png"),
        to: "/liquor"
      } // {
      //   image: require("@/assets/hotel/swiper2/2.jpg"),
      //   text: "红酒",
      //   imgOffset: "-10%",
      //   icon: require("@/assets/hotel/swiper2/icon2.png"),
      //   icon1: require("@/assets/hotel/swiper2/icon2c.png"),
      // },
      // {
      //   image: require("@/assets/hotel/swiper2/3.jpg"),
      //   text: "威士忌",
      //   imgOffset: "-30%",
      //   icon: require("@/assets/hotel/swiper2/icon3.png"),
      //   icon1: require("@/assets/hotel/swiper2/icon3c.png"),
      // },
      // {
      //   image: require("@/assets/hotel/swiper2/4.jpg"),
      //   text: "啤酒",
      //   imgOffset: "-19%",
      //   icon: require("@/assets/hotel/swiper2/icon4.png"),
      //   icon1: require("@/assets/hotel/swiper2/icon4c.png"),
      // },
      // {
      //   image: require("@/assets/hotel/swiper2/5.jpg"),
      //   text: "茶叶行业",
      //   imgOffset: "-45%",
      //   icon: require("@/assets/hotel/swiper2/icon5.png"),
      //   icon1: require("@/assets/hotel/swiper2/icon5c.png"),
      // },
      // {
      //   image: require("@/assets/hotel/swiper2/6.jpg"),
      //   text: "美妆行业",
      //   imgOffset: "-50%",
      //   icon: require("@/assets/hotel/swiper2/icon6.png"),
      //   icon1: require("@/assets/hotel/swiper2/icon6c.png"),
      // },
      // {
      //   image: require("@/assets/hotel/swiper2/7.jpg"),
      //   text: "肉食行业",
      //   imgOffset: "-44%",
      //   icon: require("@/assets/hotel/swiper2/icon7.png"),
      //   icon1: require("@/assets/hotel/swiper2/icon7c.png"),
      // },
      ],
      recommend: {
        title: "产品推荐",
        list: [{
          image: require("@/assets/hotel/recommend/1.png"),
          to: "/product?name=NFC标签&current=3"
        }, {
          image: require("@/assets/hotel/recommend/2.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/hotel/recommend/3.png"),
          to: "/product?name=RFID打印机&current=1"
        }, {
          image: require("@/assets/hotel/recommend/4.png"),
          to: "/product?name=桌面式读写器"
        }, {
          image: require("@/assets/hotel/recommend/5.png"),
          to: "/product?name=NFC标签"
        }]
      }
    };
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};